import { createRoot } from 'react-dom/client';
import 'team-hero-ui/build/index.css';
import moment from 'moment';
import 'moment/dist/locale/de';

import Providers from 'providers';
import App from 'components/App';
import reportWebVitals from './reportWebVitals';
import 'i18n/config';

// start week from Monday
moment.updateLocale('en', {
  week: {
    dow: 1,
  },
});

const container = document.getElementById('root');

const root = createRoot(container as Element);

root.render(
  <Providers>
    <App />
  </Providers>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
