import { styled } from 'styled-components';
import { Theme, Utils } from 'team-hero-ui';

const { flexFn, pxToRem } = Utils;

export const ConversationListWrapperStyled = styled.div`
  ${flexFn('flex-start', 'stretch', 'column')};
  overflow: auto;
  height: 100%;
  position: relative;
  padding: ${pxToRem(20)};
`;

export const ConversationListItemsStyled = styled.div`
  ${flexFn('flex-start', 'stretch', 'column')};
  gap: ${pxToRem(8)};
  flex-grow: 1;
  overflow: auto;
  height: ${pxToRem(40)};
`;

export const ConversationListHeader = styled.div`
  ${flexFn('flex-start')};
  background-color: ${Theme.colors.primary.white};
  color: ${Theme.colors.primary.grey};
  box-shadow: 0 ${pxToRem(3)} ${pxToRem(6)} 0 ${Theme.colors.greyShades.grey4};
  padding: ${pxToRem(12)};
  font-size: ${pxToRem(18)};
`;

export const ConversationListStyled = styled.div`
  ${flexFn('flex-start', 'stretch', 'column')};
  height: 100%;
  background-color: ${Theme.colors.greyShades.grey6};
`;

export const NoItemsStyled = styled.div`
  font-size: ${pxToRem(14)};
  padding: ${pxToRem(12)};
  text-align: center;
`;

export const ConversationItemStyled = styled.div`
  cursor: pointer;
`;
