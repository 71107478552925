import { FC, useEffect } from 'react';
import { Box, ConversationHeader, Icons, Loader, Theme } from 'team-hero-ui';

import {
  useGetMessageConversationQuery,
  useGetMessagesQuery,
  usePutMessageConversationReadUnreadMutation,
} from 'services/teamHeroApi.service';
import useCurrentUserContact from 'hooks/useCurrentUserContact.hook';
import {
  ConversationStyled,
  MessageListStyled,
} from './ClientLoginConversation.styled';
import Avatar from 'components/Avatar';
import MessageInput from 'pages/Messages/Conversation/MessageInput';
import Message from 'pages/Messages/Conversation/Message';
import { IClientLoginConversationProps } from './ClientLoginConversation.types';

const svgSize = 40;

const ClientLoginConversation: FC<IClientLoginConversationProps> = ({
  conversationId,
  isNewConversation = false,
  onClose,
  onCloseNewConversation,
}) => {
  const { currentUserContact } = useCurrentUserContact();

  const { data: conversation, isError: isErrorConversation } =
    useGetMessageConversationQuery(conversationId || 0, {
      skip: isNewConversation || !conversationId,
    });

  const {
    data: messages,
    isLoading,
    isError: isErrorMessages,
  } = useGetMessagesQuery(
    {
      conversation: conversation?.id,
      pagination: false,
    },
    {
      skip: !conversation,
    }
  );

  const onCloseHeaderClick = () => {
    onClose?.();
  };

  const [putMessageConversationReadUnread] =
    usePutMessageConversationReadUnreadMutation();

  // this should only be called once after the conversation is loaded
  useEffect(() => {
    if (conversation && conversation.totalUnreadMessages > 0) {
      putMessageConversationReadUnread({
        id: conversation.id,
        body: { status: 'read' },
      });
    }
  }, [conversation, putMessageConversationReadUnread]);

  if (isErrorConversation || isErrorMessages) {
    throw new Error('Error fetching');
  }

  if (isLoading) {
    return <Loader loaderType='static' />;
  }

  return (
    <ConversationStyled>
      {conversation && (
        <>
          <ConversationHeader
            leftSideElements={
              conversation.isGroupChat ? (
                <Icons.PeopleIcon
                  svgColor={Theme.colors.greyShades.grey2}
                  svgSize={svgSize}
                />
              ) : (
                <>
                  <Avatar
                    sourceType='file'
                    width={svgSize}
                    images={conversation.lastMessage?.fromContact?.avatar || ''}
                    firstName={conversation.lastMessage?.fromContact?.firstName}
                    lastName={conversation.lastMessage?.fromContact?.lastName}
                    skipLoading
                    thumbnailSize='small'
                  />
                  <p>
                    {conversation.lastMessage?.fromContact?.firstName}{' '}
                    {conversation.lastMessage?.fromContact?.lastName}
                  </p>
                </>
              )
            }
            onClose={onCloseHeaderClick}
          />
          <MessageListStyled>
            <Box>
              {messages?.items.map((message) => (
                <Message key={message.id} message={message} />
              ))}
            </Box>
          </MessageListStyled>
        </>
      )}
      {(conversation || isNewConversation) && (
        <MessageInput
          conversationView={
            isNewConversation ? 'new-conversation' : 'existing-conversation'
          }
          conversationIri={conversation?.['@id']}
          fromContactIri={currentUserContact?.['@id']}
          onCreateConversation={onCloseNewConversation}
        />
      )}
    </ConversationStyled>
  );
};

export default ClientLoginConversation;
