import { useCallback } from 'react';
import {
  ActionPerformed,
  PushNotifications,
  PushNotificationSchema,
  Token,
} from '@capacitor/push-notifications';
import { Capacitor } from '@capacitor/core';

import { useCurrentUser } from 'hooks/useCurrentUser';
import { usePostUserNotificationTokenMutation } from 'services/teamHeroApi.service';

interface IUseNativePushNotificationsReturn {
  addEventListeners: () => Promise<void>;
  registerNotifications: () => Promise<void>;
  unregisterNotifications: () => Promise<void>;
}

export const useNativePushNotifications =
  (): IUseNativePushNotificationsReturn => {
    const user = useCurrentUser();
    const currentPlatform = Capacitor.getPlatform();

    const [postUserNotificationToken] = usePostUserNotificationTokenMutation();

    const addEventListeners = useCallback(async () => {
      if (
        (currentPlatform !== 'android' && currentPlatform !== 'ios') ||
        !user.isLoggedIn
      ) {
        return;
      }
      PushNotifications.addListener('registration', (token: Token) => {
        // we need to update the token for the user here
        postUserNotificationToken({
          body: {
            // FCM token on android, APNS token on iOS
            token: token.value,
          },
        });
      });

      // Some issue with our setup and push will not work
      PushNotifications.addListener('registrationError', (error: unknown) => {
        // eslint-disable-next-line no-console
        console.error('Error on registration: ' + JSON.stringify(error));
      });

      // Show us the notification payload if the app is open on our device
      PushNotifications.addListener(
        'pushNotificationReceived',
        (notification: PushNotificationSchema) => {
          // eslint-disable-next-line no-console
          console.log('Push received: ' + JSON.stringify(notification));
        }
      );

      // Method called when tapping on a notification
      PushNotifications.addListener(
        'pushNotificationActionPerformed',
        (notification: ActionPerformed) => {
          // todo: add logic for routing here, dependent on body of the notification
          // eslint-disable-next-line no-console
          console.log('Push action performed: ' + JSON.stringify(notification));
        }
      );
    }, [currentPlatform, postUserNotificationToken, user.isLoggedIn]);

    const registerNotifications = useCallback(async () => {
      if (
        (currentPlatform !== 'android' && currentPlatform !== 'ios') ||
        !user.isLoggedIn
      ) {
        return;
      }

      let permStatus = await PushNotifications.checkPermissions();

      if (permStatus.receive === 'prompt') {
        permStatus = await PushNotifications.requestPermissions();
      }

      if (permStatus.receive !== 'granted') {
        // push notifications are not enabled on the device
        throw new Error('User denied permissions!');
      }

      await PushNotifications.register();
    }, [currentPlatform, user.isLoggedIn]);

    const unregisterNotifications = useCallback(async () => {
      await PushNotifications.unregister();
    }, []);

    return {
      addEventListeners,
      registerNotifications,
      unregisterNotifications,
    };
  };
