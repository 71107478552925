import { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { setNotification } from 'redux/notification/notification.slice';
import { useAppDispatch } from 'store/hooks';
import createFileUrl from 'helpers/file/getFile.helper';

const useGetFile = (
  fileName?: string | null,
  fileSource?: string | null
): { isFetching: boolean; getFile: () => void } => {
  const { t } = useTranslation('errors');
  const [isFetching, setIsFetching] = useState(false);
  const dispatch = useAppDispatch();

  const onFileDownloadError = useCallback(() => {
    dispatch(
      setNotification({
        type: 'error',
        message: t('action.downloadFileError'),
      })
    );
  }, [dispatch, t]);

  const getFile = useCallback((): void => {
    if (fileSource && fileName) {
      setIsFetching(true);

      const url = fileSource.replace('/api/', 'api/');

      createFileUrl({ apiRequestUrl: url })
        .then((fileUrl) => {
          const link = document.createElement('a');

          link.href = fileUrl;
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch(() => {
          onFileDownloadError();
        })
        .finally(() => {
          setIsFetching(false);
        });
    } else {
      onFileDownloadError();
    }
  }, [fileName, fileSource, onFileDownloadError]);

  return { isFetching, getFile };
};

export default useGetFile;
