import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Loader } from 'team-hero-ui';

import { paginatedProjectShiftsSelector } from 'redux/table/projectShifts/projectShifts.slice';
import { useAppSelector } from 'store/hooks';
import { useGetProjectShiftsWorkplannerQuery } from 'services/teamHeroApi.service';
import { useElementSize } from 'hooks/useElementSize';
import { useToggle } from 'hooks/useToggle.hook';
import TimelineHeadBar from 'components/Timeline/components/TimelineHeadBar';
import { calculateResolution } from 'components/Timeline/helper/timelineSettings.helper';
import type { ISimpleFilter } from 'hooks/useFilters.hook';
import { useGroupKeys } from 'components/WorkPlanner/hooks';
import { getDuringStringHelper } from 'helpers/dateTime/getDuringString.helper';
import { IWorkPlannerTimelineViewProps } from './WorkPlannerTimelineView.type';
import { getGroupedShiftsByKey } from 'components/Timeline/helper/shiftTimeline.helper';
import { WorkPlannerTimelineViewStyled } from './WorkPlannerTimelineView.styled';
import TimelineGroupView from './components/TimelineGroupView';
import HeadbarSettings from './components/HeadbarSettings';

const timelineConfig = {
  leftLegendWidth: 344,
  rightLegendWidth: 108,
};

const WorkPlannerTimelineView: FC<IWorkPlannerTimelineViewProps> = ({
  timelineSize,
  timelineStart,
  timelineEnd,
  mission,
}) => {
  const { t } = useTranslation('general');

  const { filters } = useAppSelector(paginatedProjectShiftsSelector);

  const shiftFilters = useMemo(
    (): ISimpleFilter[] => [
      { key: 'mission', operator: 'AND', value: mission.id },
      {
        key: 'during',
        operator: 'AND',
        value: getDuringStringHelper(
          timelineStart.toDate(),
          timelineEnd.toDate()
        ),
      },
      ...filters,
    ],
    [filters, mission.id, timelineEnd, timelineStart]
  );

  const {
    isLoading,
    isFetching,
    isError,
    data: shifts,
  } = useGetProjectShiftsWorkplannerQuery({
    pagination: false,
    filters: shiftFilters,
  });

  const [timelineRef, { width }] = useElementSize();

  const { toggleValue: showSummedShifts, toggle: toggleSummedShifts } =
    useToggle(true);

  const { groupKeys, groupToggle } = useGroupKeys();

  const { groupedShifts, groups } = getGroupedShiftsByKey(
    shifts?.items || [],
    groupKeys.mainGroupKey
  );

  const resolution = useMemo(
    () => calculateResolution(timelineSize),
    [timelineSize]
  );

  if (isError) {
    throw new Error(t('errors:errorOccurred'));
  }

  return (
    <WorkPlannerTimelineViewStyled ref={timelineRef}>
      {(isLoading || isFetching) && <Loader loaderType='absolute' />}
      {(!shifts?.items || shifts.items.length === 0) && (
        <div>{t('table.message.noItems')}</div>
      )}
      {!isLoading && shifts?.items && shifts.items.length > 0 && (
        <>
          <TimelineHeadBar
            timelineWidth={width}
            start={timelineStart}
            end={timelineEnd}
            resolution={resolution}
            leftOffset={timelineConfig.leftLegendWidth}
            legendWidth={timelineConfig.rightLegendWidth}
            headBarLeftRender={
              <HeadbarSettings
                toggleSummedShifts={toggleSummedShifts}
                showSummedShifts={showSummedShifts}
                groupToggle={groupToggle}
              />
            }
          />
          {groups.map((group) => (
            <TimelineGroupView
              showSummedShifts={showSummedShifts}
              key={group.id}
              group={group}
              shifts={groupedShifts[group.id]}
              timelineWidth={width}
              timelineSize={timelineSize}
              timelineStart={timelineStart}
              timelineEnd={timelineEnd}
              leftLegendWidth={timelineConfig.leftLegendWidth}
              rightLegendWidth={timelineConfig.rightLegendWidth}
              mainGroupKey={groupKeys.mainGroupKey}
              subGroupKey={groupKeys.subGroupKey}
              missionId={mission.id}
            />
          ))}
        </>
      )}
    </WorkPlannerTimelineViewStyled>
  );
};

export default WorkPlannerTimelineView;
