import { ReactElement, useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { TableCell } from '../TableCell/TableCell';
import type { IId } from 'interfaces/IId.interface';
import { ITableProps } from 'components/Table/Table.types';
import {
  NoItemsContainerStyled,
  StyledTableBodyInnerRow,
  StyledTableBodyRow,
} from './TableBody.styled';

export interface ITableRowsProps<T extends IId>
  extends Omit<ITableProps<T>, 'sorting'> {}

export const TableBody = <T extends IId>({
  data,
  columns,
  rowIdMenuOpen,
  isMin,
  selectedId,
  styleType,
  onRowClick,
  editRowComponent,
  rowCollapsibleContentRenderer,
  bottomRowRenderer,
  topRowRenderer,
}: ITableRowsProps<T>): ReactElement => {
  const { t } = useTranslation('general');
  const ref = useRef<HTMLDivElement>(null);

  const scrollToSelectedElement = useCallback(() => {
    ref?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  }, []);

  useEffect(() => {
    if (isMin) {
      scrollToSelectedElement();
    }
  }, [isMin, scrollToSelectedElement]);

  const getRowEdit = useCallback(
    (id: number) => {
      if (rowIdMenuOpen === id && editRowComponent) {
        const EditRowComponent = editRowComponent;
        return <EditRowComponent />;
      }
      return null;
    },
    [editRowComponent, rowIdMenuOpen]
  );

  const getCollapsibleRow = useCallback(
    (rowId: number) => {
      return rowCollapsibleContentRenderer
        ? rowCollapsibleContentRenderer(rowId, columns)
        : null;
    },
    [columns, rowCollapsibleContentRenderer]
  );

  return (
    <div data-component='table-body'>
      {topRowRenderer}
      <>
        {data.length > 0 ? (
          <>
            {data.map((row, index) => {
              const onClick = () => onRowClick && onRowClick(row.id);

              return (
                <StyledTableBodyRow
                  key={`row-${row.id}`}
                  ref={ref}
                  onClick={onClick}
                  isMin={isMin}
                  styleType={styleType}
                  data-component={'table-body-row'}
                  data-test-id={`table-body-row-${index}`}
                >
                  <StyledTableBodyInnerRow columns={columns}>
                    {columns.map((column) => {
                      return column.visibility ? (
                        <TableCell
                          onRowClick={onRowClick}
                          columnDefinition={column}
                          rowItem={row}
                          isMin={isMin}
                          selectedId={selectedId}
                          styleType={styleType}
                          key={`cell-${row.id}-${column.key}`}
                        />
                      ) : null;
                    })}
                    {getRowEdit(row.id)}
                  </StyledTableBodyInnerRow>
                  {getCollapsibleRow(row.id)}
                </StyledTableBodyRow>
              );
            })}
          </>
        ) : (
          <NoItemsContainerStyled
            data-test-id='table-no-items-container'
            $styleType={styleType}
          >
            {t('table.message.noItems')}
          </NoItemsContainerStyled>
        )}
      </>
      {bottomRowRenderer}
    </div>
  );
};
