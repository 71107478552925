import { styled } from 'styled-components';
import { Theme, Utils } from 'team-hero-ui';

const { flexFn, pxToRem } = Utils;

export const NoResultsStyled = styled.div`
  ${flexFn('center', 'center')};
  margin-top: ${pxToRem(40)};
  width: 100%;
  font-size: ${pxToRem(22)};
  color: ${Theme.colors.greyShades.grey1};
`;
