import {
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query';
import { retry } from '@reduxjs/toolkit/query/react';

import type { IPostCredentialsResponse } from '../user/user.types';
import { store } from 'store';
import { logoutByForce } from 'redux/user/user.thunk';
import {
  getRefreshToken,
  getToken,
  removeRefreshToken,
  removeToken,
  storeRefreshToken,
  storeToken,
} from '../user/user.helper';

export const customBaseQueryFn = fetchBaseQuery({
  baseUrl: import.meta.env.VITE_API_URI,
  prepareHeaders: (headers: Headers) => {
    // token in localStorage :(
    const token = getToken();
    if (token) {
      headers.set('authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

export const noAuthCustomBaseQueryFn = fetchBaseQuery({
  baseUrl: import.meta.env.VITE_API_URI,
});

const resetUser = async () => {
  removeRefreshToken();
  removeToken();
  return store.dispatch(logoutByForce());
};

/**
 * interceptor that tries to reauthenticate, if token is no longer valid
 */
export const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await customBaseQueryFn(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    // check if refresh token is set
    const refreshToken = getRefreshToken();
    // if refresh token is set, fetch new token with refresh token
    if (refreshToken) {
      const refreshResult = await customBaseQueryFn(
        {
          url: '/api/token/refresh',
          body: {
            refresh_token: refreshToken,
          },
          method: 'POST',
        },
        api,
        extraOptions
      );
      if (refreshResult.data) {
        const { token: tokenReceived, refresh_token: refreshTokenReceived } =
          refreshResult.data as IPostCredentialsResponse;
        storeToken(tokenReceived);
        storeRefreshToken(refreshTokenReceived);
      } else {
        // handle refreshTokenFetchError: Log out user ?? and remove tokens ?? redirect to login page
        await resetUser();
        // stop retrying
        retry.fail(result.error);
      }
    } else {
      await resetUser();
      // stop retrying
      retry.fail(result.error);
    }
    result = await customBaseQueryFn(args, api, extraOptions);
  }
  return result;
};
